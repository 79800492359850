import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@public/svg/icons/close.svg'
import SpyGlassIcon from '@public/svg/icons/spyglass.svg'

import { COMMON_WHITE, GRAY_GAMEBOY, TEAL_DOLLHOUSE } from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'

import SvgLoader from '@components_pop/SvgLoader'

const visibleSearchInputStyles = {
  opacity: 1,
  visibility: 'visible',
  width: 240,
  padding: 'inherit',
}

const useStyles = makeStyles((theme) => ({
  searchInput: {
    outline: 'none',
    border: 'none',
    flex: 1,
    fontSize: 16,
    background: 'transparent',
    color: 'rgba(0, 67, 89, 0.5)',
    width: 0,
    padding: 0,
    transition: 'opacity .3s, visibility .3s, width .3s',
    marginLeft: 20,
    '&::placeholder': {
      color: 'rgba(0, 67, 89, 0.5)',
    },
  },
  searchOutline: {
    padding: '10px 15px',
    display: 'flex',
    height: 40,
    background: 'rgba(13, 118, 128, 0.2);',
    borderRadius: 30,
    alignItems: 'center',
    marginBottom: 20,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    height: 20,
    width: 20,
    padding: 0,
  },
  deleteLabel: {
    display: 'inline-block', // fix icon not showing in chrome
  },
  iconStyles: {
    width: 20,
    height: 20,
  },
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    searchInput: {
      visibility: 'hidden',
      opacity: 0,
      fontSize: 20,
      fontWeight: 600,
      marginLeft: 0,
      color: COMMON_WHITE,
      '&::placeholder': {
        color: COMMON_WHITE,
      },
      '&:focus': visibleSearchInputStyles,
      '&:not(:placeholder-shown)': visibleSearchInputStyles,
    },
    searchOutline: {
      height: 52,
      background: 'rgba(247, 246, 245, 0.4)',
      marginBottom: 0,
      '&:hover > input': visibleSearchInputStyles,
    },
  },
}))

const useAlternateStyles = makeStyles(() => ({
  searchOutline: {
    display: 'flex',
    padding: '10px 15px',
    height: 40,
    background: 'rgba(222, 219, 217, 0.4)',
    borderRadius: 40,
  },
  iconStyles: {
    width: 14,
    height: 14,
  },
  searchInput: {
    outline: 'none',
    border: 'none',
    flex: 1,
    fontSize: 14,
    background: 'transparent',
    color: TEAL_DOLLHOUSE,
    marginLeft: 10,
    '&::placeholder': {
      color: GRAY_GAMEBOY,
    },
  },
}))

const SearchInput = React.forwardRef(
  ({ onSubmit, inputText, currentModule, isLoading, setValue, hasAlternateDesign }, ref) => {
    const classes = hasAlternateDesign ? useAlternateStyles() : useStyles()

    return (
      <form onSubmit={onSubmit}>
        <div className={classes.searchOutline} data-test-id="global-search">
          <SvgLoader className={classes.iconStyles} {...SpyGlassIcon} />
          <input
            name="globalSearch"
            defaultValue={inputText}
            ref={ref}
            className={classes.searchInput}
            type="text"
            placeholder={hasAlternateDesign ? `search ${currentModule.text}` : 'search'}
            autoComplete="off"
          />
          {isLoading && <FontAwesomeIcon icon={faSpinnerThird} color={COMMON_WHITE} spin />}
          {inputText && (
            <IconButton
              onClick={() => {
                currentModule.searchVar('')
                setValue('globalSearch', '')
              }}
              classes={{ label: classes.deleteLabel }}
              size="small"
            >
              <SvgLoader className={classes.iconStyles} {...CloseIcon} />
            </IconButton>
          )}
        </div>
      </form>
    )
  }
)

SearchInput.propTypes = {
  onSubmit: PropTypes.func,
  inputText: PropTypes.string,
  isLoading: PropTypes.bool,
  hasAlternateDesign: PropTypes.bool,
  setValue: PropTypes.func,
  currentModule: PropTypes.shape({
    text: PropTypes.string.isRequired,
    searchVar: PropTypes.func,
  }),
}

SearchInput.defaultProps = {
  onSubmit: () => {},
  inputText: '',
  currentModule: {
    text: '',
    searchVar: () => {},
  },
}

export default SearchInput
