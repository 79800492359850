import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

import { clickedNotification } from '@lib/apollo/apolloCache'
import { NPI_NOTIFICATION_TYPES } from '@lib/constants/notifications'
import usePusherHelpers from '@lib/pusher/usePusherHelper'

import { CLEAR_ALL_NOTIFICATIONS, CLEAR_NOTIFICATION } from '@graphql/notifications/mutators'
import { GET_NOTIFICATIONS, GET_NOTIFICATION_COUNTS } from '@graphql/notifications/queries'

import NotificationList from './view'

const NotificationListContainer = ({ open, buttonAnchorEl, notifications, onMenuClose }) => {
  const router = useRouter()
  const { removePollingGraphVar } = usePusherHelpers()

  const [clearNotification] = useMutation(CLEAR_NOTIFICATION, {
    refetchQueries: removePollingGraphVar([
      { query: GET_NOTIFICATIONS },
      { query: GET_NOTIFICATION_COUNTS },
    ]),
  })

  const [clearAllNotifications] = useMutation(CLEAR_ALL_NOTIFICATIONS, {
    refetchQueries: removePollingGraphVar([
      { query: GET_NOTIFICATIONS },
      { query: GET_NOTIFICATION_COUNTS },
    ]),
  })

  const notificationsCounts = useQuery(GET_NOTIFICATION_COUNTS)
  const defaultPageSize = notificationsCounts?.data?.unclearedNotificationsCount?.pageSize ?? 0
  const unreadNotificationsCount =
    notificationsCounts?.data?.unclearedNotificationsCount?.unreadNotificationsCount ?? 0
  const totalNotificationsCount =
    unreadNotificationsCount > 5000 ? '5000+' : unreadNotificationsCount
  const pageSize =
    defaultPageSize > totalNotificationsCount ? totalNotificationsCount : defaultPageSize

  const handleClickNotification = (notification) => () => {
    const { notificationType, notificationItemId, projectSlug } = notification
    clickedNotification(notification)

    if (NPI_NOTIFICATION_TYPES.includes(notificationType)) {
      router.push(
        {
          pathname: '/project/[projectSlug]',
          query: { npi_step_id: notificationItemId },
        },
        {
          pathname: `/project/${projectSlug}`,
          query: { npi_step_id: notificationItemId },
        },
        { shallow: true }
      )
    } else {
      router.push(
        { pathname: '/project/[projectSlug]', query: { messageid: notificationItemId } },
        { pathname: `/project/${projectSlug}`, query: { messageid: notificationItemId } },
        { shallow: true }
      )
    }
    onMenuClose()
  }

  const handleClearNotification = (notificationId) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    clearNotification({
      variables: { notificationId },
    }).then(
      (/* res */) => {},
      (/* err */) => {}
    )
  }

  const handleClearAll = (event) => {
    event.stopPropagation()
    clearAllNotifications().then(
      (/* res */) => {},
      (/* err */) => {}
    )
  }

  return (
    <NotificationList
      open={open}
      desktopAnchorEl={buttonAnchorEl}
      notifications={notifications}
      onClickNotification={handleClickNotification}
      onClearNotification={handleClearNotification}
      onClearAll={handleClearAll}
      onClose={onMenuClose}
      pageSize={pageSize}
      totalNotificationsCount={totalNotificationsCount}
    />
  )
}

NotificationListContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  buttonAnchorEl: PropTypes.shape({}),
  notifications: PropTypes.array.isRequired,
  onMenuClose: PropTypes.func.isRequired,
}

export default NotificationListContainer
