import PropTypes from 'prop-types'
import React from 'react'

import { Grid, IconButton, Tabs, Tab, Badge } from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@public/svg/icons/close-outline.svg'

import {
  BOX_BORDER_COLOR,
  TEAL_DOLLHOUSE,
  GRAY_WII,
  PURPLE_POLLYPOCKET,
  TEAL_FURBY,
  TEAL_POGS,
  TEAL_LITEBRITE,
} from '@lib/colors'
import { NOTIFICATION_TYPE, NPI_NOTIFICATION_TYPES } from '@lib/constants/notifications'
import { serializeData } from '@lib/tracking'

import SvgLoader from '@components_pop/SvgLoader'

import AttachmentNotification from './AttachmentNotification'
import ChatMentionNotification from './ChatMentionNotification'
import ChatMessageNotification from './ChatMessageNotification'
import NpiNotification from './NpiNotification'

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    padding: '0 1rem',
    borderBottom: `1px solid ${BOX_BORDER_COLOR}`,
    width: '100%',
    '&:hover': {
      backgroundColor: TEAL_LITEBRITE,
    },
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
  rowButtonBase: {
    padding: `${theme.spacing(2)}px 0`,
    textAlign: 'left',
    width: '100%',
  },
  clearItemButton: {
    marginTop: theme.spacing(),
  },
  notificationContent: {
    color: TEAL_DOLLHOUSE,
    padding: 0,
    flexGrow: 1,
  },
  tabs: {
    borderBottom: `3px solid ${TEAL_DOLLHOUSE}`,
    margin: '0 10px 1rem',
    '& .MuiTabs-flexContainer': {
      alignItems: 'flex-end',
      minHeight: 50,
    },
  },
  notificationsTabs: {
    background: TEAL_DOLLHOUSE,
    color: GRAY_WII,
    flex: 1,
    height: 38,
    margin: 0,
    '&:not(:last-child)': {
      marginRight: 5,
    },
    padding: 0,
    paddingTop: 12,
    opacity: '1',
    minHeight: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minWidth: 72,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    transition: 'all .2s ease-out',
    '&.Mui-selected': {
      background: TEAL_POGS,
      color: TEAL_DOLLHOUSE,
      height: 50,
    },
    '&:hover': {
      background: TEAL_FURBY,
      color: GRAY_WII,
    },
  },
  tabContent: {
    textTransform: 'none',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 600,
  },
  notificationGroupTitle: {
    color: TEAL_DOLLHOUSE,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 12,
    lineHeight: '13px',
    fontWeight: 700,
    padding: '0 1rem',
    overflow: 'hidden',
  },
  notificationCountBadge: {
    background: PURPLE_POLLYPOCKET,
    color: TEAL_DOLLHOUSE,
    width: 20,
    height: 14,
    padding: 0,
    fontSize: 10,
    position: 'relative',
    transform: 'none',
    marginLeft: 10,
  },
  closeIcon: {
    width: 20,
    height: 'auto',
  },
}))

const NotificationCountBadge = ({ count }) => {
  const classes = useStyles()

  if (!count) return null
  return (
    <Badge
      badgeContent={count}
      classes={{ badge: classes.notificationCountBadge }}
      style={{ verticalAlign: 'unset' }}
    />
  )
}

NotificationCountBadge.propTypes = {
  count: PropTypes.number,
}

const NotificationsByProject = ({ notifications: { count, ...notificationsByProject } }) => {
  const classes = useStyles()
  return Object.keys(notificationsByProject).map((projectName) => (
    <div key={projectName}>
      <div className={classes.notificationGroupTitle}>{projectName}</div>
      {notificationsByProject[projectName]}
    </div>
  ))
}

NotificationsByProject.propTypes = {
  notifications: PropTypes.shape({ count: PropTypes.number, [PropTypes.string]: PropTypes.array }),
}

export default function NotificationItems({
  notifications,
  onClickNotification,
  onClearNotification,
}) {
  const classes = useStyles()
  // groups notifications by category and project
  const notificationItems = notifications.reduce(
    (notificationsByCategory, n) => {
      const {
        description,
        projectName,
        companyName,
        posterFirstName,
        posterLastName,
        notificationType,
        mediaMimetype,
      } = n

      const notificationProps = {
        notificationType,
        projectName,
        companyName,
        description,
        posterFirstName,
        posterLastName,
        mediaMimetype,
      }

      // eslint fix - avoid mutating the `notificationsByCategory` param
      const groupedNotifications = { ...notificationsByCategory }

      let notificationItem = null
      let notificationCategory = null

      if (notificationType === NOTIFICATION_TYPE.AT_MENTION) {
        notificationItem = <ChatMentionNotification {...notificationProps} />
        notificationCategory = 'mentions'
      } else if (notificationType === NOTIFICATION_TYPE.CHAT_MESSAGE && mediaMimetype) {
        notificationItem = <AttachmentNotification {...notificationProps} />
        notificationCategory = 'attachmentsAndMsgs'
      } else if (notificationType === NOTIFICATION_TYPE.CHAT_MESSAGE) {
        notificationItem = <ChatMessageNotification {...notificationProps} />
        notificationCategory = 'attachmentsAndMsgs'
      } else if (NPI_NOTIFICATION_TYPES.includes(notificationType)) {
        notificationItem = <NpiNotification {...notificationProps} />
        notificationCategory = 'npi'
      } else {
        return groupedNotifications
      }

      const notificationElement = (
        <Grid key={n.id} container direction="row" wrap="nowrap" className={classes.rowContainer}>
          <Grid
            item
            className={classes.notificationContent}
            data-test-id={`notification-bell--list-item--${n.notificationItemId}`}
          >
            <ButtonBase className={classes.rowButtonBase} onClick={onClickNotification(n)}>
              {notificationItem}
            </ButtonBase>
          </Grid>
          <Grid item className={classes.clearItemButton}>
            <IconButton
              aria-label="clear"
              onClick={onClearNotification(n.id)}
              data-tracking-info={serializeData({
                id: 'header_clear-notification_click',
                notificationId: n.id,
              })}
            >
              <SvgLoader {...CloseIcon} className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
      )

      // if the group exists, push the element into it, otherwise create a new array
      if (groupedNotifications[notificationCategory][n.projectName]) {
        groupedNotifications[notificationCategory][n.projectName].push(notificationElement)
      } else {
        groupedNotifications[notificationCategory][n?.projectName] = [notificationElement]
      }
      groupedNotifications[notificationCategory].count += 1

      return groupedNotifications
    },
    {
      attachmentsAndMsgs: { count: 0 },
      mentions: { count: 0 },
      npi: { count: 0 },
    }
  )

  let defaultIndex

  if (notificationItems.mentions.count) {
    defaultIndex = 0
  } else if (notificationItems.attachmentsAndMsgs.count) {
    defaultIndex = 1
  } else if (notificationItems.npi.count) {
    defaultIndex = 2
  }

  const [tabIndex, setTabIndex] = React.useState(defaultIndex)

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex)
  }

  // accessibility props suggested by https://material-ui.com/components/tabs/
  const a11yProps = (index) => {
    return {
      id: `notifications-tab-${index}`,
      'aria-controls': `notifications-tabpanel-${index}`,
    }
  }

  return (
    <>
      <Tabs
        value={tabIndex}
        TabIndicatorProps={{ style: { display: 'none' } }}
        className={classes.tabs}
        onChange={handleChange}
        aria-label="Notification Tabs"
      >
        <Tab
          disabled={!notificationItems.mentions.count}
          {...a11yProps(0)}
          className={classes.notificationsTabs}
          label={
            <div className={classes.tabContent}>
              <span>
                Mentions
                <NotificationCountBadge count={notificationItems.mentions.count} />
              </span>
            </div>
          }
        />
        <Tab
          disabled={!notificationItems.attachmentsAndMsgs.count}
          {...a11yProps(1)}
          className={classes.notificationsTabs}
          label={
            <div className={classes.tabContent}>
              <span>Messages</span>
              <NotificationCountBadge count={notificationItems.attachmentsAndMsgs.count} />
            </div>
          }
        />
        <Tab
          disabled={!notificationItems.npi.count}
          {...a11yProps(2)}
          className={classes.notificationsTabs}
          label={
            <div className={classes.tabContent}>
              <span>Journey</span>
              <NotificationCountBadge count={notificationItems.npi.count} />
            </div>
          }
        />
      </Tabs>
      {tabIndex === 0 && <NotificationsByProject notifications={notificationItems.mentions} />}
      {tabIndex === 1 && (
        <NotificationsByProject notifications={notificationItems.attachmentsAndMsgs} />
      )}
      {tabIndex === 2 && <NotificationsByProject notifications={notificationItems.npi} />}
    </>
  )
}

NotificationItems.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickNotification: PropTypes.func.isRequired,
  onClearNotification: PropTypes.func.isRequired,
}
