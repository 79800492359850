import gql from 'graphql-tag'

export const GET_NOTIFICATIONS_LOCAL = gql`
  query GetNotificationsWrapper {
    notificationsWrapper @client
  }
`

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on NotificationQuerysetType {
    id
    notificationAcknowledgedAt
    notificationType
    notificationItemId
    description
    projectId
    projectName
    projectSlug
    companyName
    posterFirstName
    posterLastName
    thirdPartyPosterAccountName
    thirdPartyPosterFirstName
    thirdPartyPosterLastName
    hasAtMention
    mediaMimetype
    channelId
  }
`

export const GET_NOTIFICATIONS = gql`
  query GetUnclearedNotifications {
    unclearedNotifications {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`

export const GET_NOTIFICATION_COUNTS = gql`
  query UnclearedNotificationsCount {
    unclearedNotificationsCount {
      unreadNotificationsCount
      totalPageCount
      pageSize
    }
  }
`
