import { useMutation, useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import { currentUser } from '@lib/apollo/apolloCache'

import { UPDATE_USER_AVAILABILITY } from '@graphql/user/mutators'

import { TOAST_TYPES } from '@components_pop/Toast'
import UserProfileMenuView from '@components_pop/profile/UserProfileMenu/view'

import useToast from '@hooks/useToast'

export const AVAILABILITY_CHOICES = {
  ACTIVE: 'active',
  OOO: 'out_of_office',
}

export const DEFAULT_CATCH_ALL_MAILS = {
  AM: 'accountmanagement@gembah.com',
  PM: 'supplychain@gembah.com',
  DESIGN: 'design@gembah.com',
}

const UserProfileMenuContainer = ({ anchorEl }) => {
  const user = useReactiveVar(currentUser)
  const { addToast } = useToast()
  const [updateUserAvailability] = useMutation(UPDATE_USER_AVAILABILITY)

  const handleStatusChange = () => {
    const availability =
      user.availability === AVAILABILITY_CHOICES.ACTIVE
        ? AVAILABILITY_CHOICES.OOO
        : AVAILABILITY_CHOICES.ACTIVE

    updateUserAvailability({
      variables: {
        availability,
        userId: user.id,
      },
    }).then((res) => {
      if (res.errors) {
        addToast({
          message: 'Could not update working status',
          type: TOAST_TYPES.ERROR,
        })
        return
      }
      const { coveredByUser, coveredByEmail } = user
      const backupPersonMsg = coveredByUser
        ? `Backup person is "${coveredByUser?.firstName} ${coveredByUser?.lastName}`
        : `Default backup email is "${coveredByEmail}`

      addToast({
        message: `
          Succesfully updated working status.
          ${availability === AVAILABILITY_CHOICES.OOO ? backupPersonMsg : ''}
        `,
        type: TOAST_TYPES.SUCCESS,
      })
      currentUser({
        ...user,
        availability,
      })
    })
  }

  return <UserProfileMenuView anchorEl={anchorEl} onStatusChange={handleStatusChange} />
}

UserProfileMenuContainer.propTypes = {
  anchorEl: PropTypes.shape({}),
}

UserProfileMenuContainer.defaultProps = {
  anchorEl: null,
}

export default UserProfileMenuContainer
