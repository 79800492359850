import { useQuery, useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { usePageVisibility } from 'react-page-visibility'

import { featureFlags, notificationsWrapper } from '@lib/apollo/apolloCache'
import {
  NOTIFICATIONS_POLL_INTERVAL,
  NOTIFICATIONS_POLL_INTERVAL_WITH_REALTIME,
} from '@lib/apollo/apolloConfig'

import { GET_NOTIFICATIONS } from '@graphql/notifications/queries'

import NotificationBellContainer from './container'

const NotificationBellApollo = () => {
  const { isRealtimeActive } = useReactiveVar(featureFlags)
  const isPageVisible = usePageVisibility()
  const pollInterval = isRealtimeActive
    ? NOTIFICATIONS_POLL_INTERVAL_WITH_REALTIME
    : NOTIFICATIONS_POLL_INTERVAL

  // ===============================================================
  // Polling notification query.
  // Updates the notificationsWrapper reactive variable in cache.
  // ===============================================================

  const {
    data: notificationData,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery(GET_NOTIFICATIONS, {
    pollInterval,
  })

  useEffect(() => {
    if (isPageVisible) {
      // refetch to catch up on any missed notifications, then resume polling.
      refetch().then(() => {
        startPolling(pollInterval)
      })
    } else {
      stopPolling()
    }
  }, [isPageVisible])

  if (notificationData) {
    const newData = {
      ...notificationsWrapper(),
    }

    if (notificationData.unclearedNotifications) {
      newData.notifications = [...notificationData.unclearedNotifications]
    }

    notificationsWrapper(newData)
  }

  return <NotificationBellContainer />
}

export default NotificationBellApollo
