import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

import AddIcon from '@public/svg/icons/plus.svg'
import Logo from '@public/svg/logo.svg'

import { currentAccountUser } from '@lib/apollo/apolloCache'
import * as COLORS from '@lib/colors'
import { COMMON_WHITE, TEAL_DOLLHOUSE, TEAL_POGS } from '@lib/colors'
import { USER_ID_SESSION_STORAGE_KEY } from '@lib/constants'
import { DESKTOP_CENTER_SPACE, MOBILE_BREAKPOINT, MOBILE_CENTER_SPACE } from '@lib/theme'
import { serializeData } from '@lib/tracking'
import useRoute from '@lib/useRoute'
import { hasPermission } from '@lib/userAuth'

import SearchInput from '@components_pop/SearchInput'
import SvgLoader from '@components_pop/SvgLoader'
import NotificationBell from '@components_pop/notifications/NotificationBell'
import UserMenuPill from '@components_pop/profile/UserProfilePill'

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    backgroundColor: COLORS.TEAL_FURBY,
    color: COLORS.COMMON_WHITE,
    padding: `0 ${MOBILE_CENTER_SPACE}px`,
    borderBottom: `3px solid ${COLORS.TEAL_DOLLHOUSE}`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 80,
    padding: 0,
  },
  toolbarLogoContainer: {
    backgroundColor: COLORS.PRIMARY_BLACK_BACKGROUND,
    height: '100%',
    width: 110,
    flexGrow: 1,
    paddingLeft: 90, // hack to center the logo
  },
  logoLink: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& svg': {
      height: 35,
      width: 'auto',
    },
  },
  hamburgerIcon: {
    color: COLORS.COMMON_WHITE,
  },
  mobileMenuWrapper: {
    display: 'flex',
    // Do not set this to position absolute! Will affect the mobile notification bell list
    right: theme.spacing(),
  },
  toolbarContent: {
    display: 'flex',
    flex: 1,
  },
  logoAndSearchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  userUtilsContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginLeft: 10,
    },
  },
  bellPlaceholder: {
    width: 48,
    height: 48,
  },
  searchWrapper: {
    display: 'none',
  },
  addIconStyles: {
    width: 32,
    height: 32,
  },
  additionalLinksWrapperLink: {
    display: 'flex',
    alignItems: 'center',
    background: TEAL_DOLLHOUSE,
    padding: '12px',
    color: COMMON_WHITE,
    borderRadius: '8px 8px 0 0',
    fontWeight: 600,
    fontSize: 12,
    textDecoration: 'none',
    margin: '0 0 -46px 10px',
    cursor: 'pointer',
    transition: 'color 0.5s, background 0.3s, padding 0.3s, margin 0.3s',
    '&:hover': {
      color: TEAL_DOLLHOUSE,
      background: TEAL_POGS,
      padding: '20px 12px',
      marginBottom: -26,
    },
    '&:visited:not(:hover)': {
      color: COMMON_WHITE,
    },
  },
  activeLink: {
    background: TEAL_POGS,
    padding: '20px 12px',
    color: TEAL_DOLLHOUSE,
    margin: '0 0 -26px 10px',
    '&:visited:not(:hover)': {
      color: TEAL_DOLLHOUSE,
    },
  },
  additionalLinksWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    userUtilsContainer: {
      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
      '& > *:last-child': {
        marginLeft: 25,
      },
    },
    additionalLinksWrapperLink: {
      fontSize: 20,
      margin: '0 0 -34px 10px',
      '&:hover': {
        marginBottom: -14,
      },
    },
    activeLink: {
      margin: '0 0 -14px 10px',
    },
    appBarRoot: {
      padding: `0 ${DESKTOP_CENTER_SPACE}px`,
    },
    searchWrapper: {
      display: 'block',
      marginLeft: 40,
    },
    masquerading: {
      top: 50,
    },
  },
}))

const HeaderPop = ({ forceHideNavbarSearch }) => {
  const classes = useStyles()
  const accountUser = useReactiveVar(currentAccountUser)
  const [goTo] = useRoute()
  const { pathname } = useRouter()
  const userIdInSessionStorage = sessionStorage.getItem(USER_ID_SESSION_STORAGE_KEY)

  const linkWithStateClasses = clsx({
    [classes.additionalLinksWrapperLink]: true,
    [classes.activeLink]: pathname === '/',
  })

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx({ [classes.masquerading]: userIdInSessionStorage })}
        classes={{ root: classes.appBarRoot }}
      >
        <Toolbar classes={{ root: classes.toolbar }}>
          <div className={classes.logoAndSearchWrapper}>
            <Link href="/">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <SvgLoader {...Logo} />
              </a>
            </Link>
            <div className={classes.searchWrapper}>{!forceHideNavbarSearch && <SearchInput />}</div>
          </div>
          <div className={classes.toolbarContent}>
            <div className={classes.userUtilsContainer}>
              <div className={classes.additionalLinksWrapper}>
                <Link href="/">
                  <a className={linkWithStateClasses} data-test-id="nav--projects">
                    my projects
                  </a>
                </Link>
                {hasPermission.CREATOR(accountUser) && (
                  <Link target="_blank" href={`https://${process.env.MARKETPLACE_HOST}/resources`}>
                    <a className={classes.additionalLinksWrapperLink} data-test-id="nav--resources">
                      my resources
                    </a>
                  </Link>
                )}
              </div>
              <NotificationBell />
              {hasPermission.GEMBAH(accountUser) && (
                <IconButton
                  onClick={() => {
                    goTo('/project/add')
                  }}
                  data-tracking-info={serializeData({
                    id: 'header_add-new-project-button_click',
                  })}
                  size="small"
                >
                  <SvgLoader className={classes.addIconStyles} {...AddIcon} />
                </IconButton>
              )}
              <UserMenuPill />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} /> {/* AppBar spacer */}
    </>
  )
}

HeaderPop.propTypes = {
  forceHideNavbarSearch: PropTypes.bool,
}

export default HeaderPop
