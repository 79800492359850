import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CloseIcon from '@public/svg/icons/close.svg'

import { GRAY_NICKELARCADE, TEAL_DOLLHOUSE } from '@lib/colors'
import { lightButton, MOBILE_APPBAR_HEIGHT, MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

import SvgLoader from '@components_pop/SvgLoader'

import NotificationItems from './NotificationItems'

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.drawer + 1,
  },
  slideContainer: {
    height: '100%',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
  },
  mobileContentContainer: {
    background: theme.palette.common.white,
    marginTop: MOBILE_APPBAR_HEIGHT,
    paddingBottom: MOBILE_APPBAR_HEIGHT,
    overflow: 'auto',
    position: 'relative',
    width: '100vw',
    height: 'inherit',
    left: 0,
    right: 0,
    bottom: 0,
  },
  notificationListBox: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    marginTop: 6,
    background: 'white',
    width: theme.spacing(60),
    minHeight: theme.spacing(2),
    maxHeight: '50vh',
    border: 'none',
    borderRadius: '20px',
    boxShadow: '0px 3px 15px rgba(149, 145, 142, 0.5)',
  },
  boxContent: {
    width: '100%',
    overflow: 'auto',
  },
  listHeader: {
    color: theme.palette.common.black,
    padding: `0 ${theme.spacing(2)}px`,
  },
  notificationItems: {
    width: '100%',
  },
  listContainer: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  notificationListHeading: {
    color: TEAL_DOLLHOUSE,
  },
  notificationPaginationLabel: {
    fontSize: 15,
    lineHeight: '32px',
    color: GRAY_NICKELARCADE,
    textTransform: 'lowercase',
  },
  notificationClearButton: {
    ...lightButton,
  },
  mobileListHeader: {
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    display: 'flex',
  },
  mobileListContainer: {
    paddingTop: theme.spacing(8), // notification list header height
  },
  notificationListHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h2': {
      margin: 0,
    },
    '& h6': {
      lineHeight: 1,
    },
  },
  closeIcon: {
    width: 20,
    height: 'auto',
  },
}))

const NOTIFICATIONS_CLEAR_ALL_SHOW_THRESHOLD = 3

const NotificationList = ({
  open,
  desktopAnchorEl,
  notifications,
  onClickNotification,
  onClearNotification,
  onClearAll,
  onClose,
  pageSize,
  totalNotificationsCount,
}) => {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up(MOBILE_BREAKPOINT), {
    noSsr: true,
  })

  if (!notifications || !notifications.length) {
    return null // A confetti toss is handled by the Notification Bell component click, not here.
  }

  const listHeaderClass = clsx(classes.listHeader, {
    [classes.mobileListHeader]: !isDesktop,
  })

  if (isDesktop) {
    return (
      <Popper open={open} anchorEl={desktopAnchorEl} className={classes.popper}>
        <ClickAwayListener onClickAway={onClose}>
          <div className={classes.notificationListBox}>
            <div
              className={classes.boxContent}
              data-test-id="notification-bell--notification-list--scrollable-area"
            >
              <Grid container direction="column">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className={listHeaderClass}
                >
                  <Typography variant="h2" className={classes.notificationListHeading}>
                    Notifications
                  </Typography>
                  <Typography variant="subtitle1" className={classes.notificationPaginationLabel}>
                    showing {pageSize} of {totalNotificationsCount}
                  </Typography>

                  {notifications?.length > NOTIFICATIONS_CLEAR_ALL_SHOW_THRESHOLD && (
                    <Button
                      className={classes.notificationClearButton}
                      onClick={onClearAll}
                      data-tracking-info={serializeData({
                        id: 'header_clear-all-notifications_click',
                      })}
                    >
                      Clear
                    </Button>
                  )}
                </Grid>
                <Grid item className={classes.notificationItems}>
                  <NotificationItems
                    notifications={notifications}
                    onClickNotification={onClickNotification}
                    onClearNotification={onClearNotification}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    )
  }

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div className={classes.slideContainer}>
        <ClickAwayListener onClickAway={onClose}>
          <div className={classes.mobileContentContainer}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              className={listHeaderClass}
            >
              <div className={classes.notificationListHeaderWrapper}>
                <Typography variant="h2" className={classes.notificationListHeading}>
                  Notifications
                </Typography>
                <Typography variant="subtitle2" className={classes.notificationPaginationLabel}>
                  showing {pageSize} of {totalNotificationsCount}
                </Typography>
              </div>
              {notifications?.length > NOTIFICATIONS_CLEAR_ALL_SHOW_THRESHOLD && (
                <Button className={classes.notificationClearButton} onClick={onClearAll}>
                  Clear all
                </Button>
              )}
              <IconButton onClick={onClose}>
                <SvgLoader {...CloseIcon} className={classes.closeIcon} />
              </IconButton>
            </Grid>
            <div className={classes.mobileListContainer}>
              <NotificationItems
                notifications={notifications}
                onClickNotification={onClickNotification}
                onClearNotification={onClearNotification}
              />
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </Slide>
  )
}

NotificationList.propTypes = {
  open: PropTypes.bool.isRequired,
  desktopAnchorEl: PropTypes.shape({}),
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageSize: PropTypes.number.isRequired,
  totalNotificationsCount: PropTypes.number.isRequired,
  onClickNotification: PropTypes.func.isRequired,
  onClearNotification: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default NotificationList
