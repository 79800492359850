import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import BellIcon from '@public/svg/icons/bell.svg'

import { serializeData } from '@lib/tracking'

import SvgLoader from '@components_pop/SvgLoader'
import NotificationList from '@components_pop/notifications/NotificationList'

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    position: 'relative',
  },
  badge: {
    boxShadow: '-2px 2px 3px 0px rgba(0,0,0,0.35)',
  },
  bellIcon: {
    width: 32,
    height: 32,
  },
}))

const NotificationBell = ({
  notifications,
  notificationsCount,
  onMenuToggle,
  onMenuClose,
  showNotifications,
}) => {
  const buttonAnchorRef = useRef()
  const classes = useStyles()

  return (
    <>
      <div
        className={classes.root}
        data-tracking-info={serializeData({
          id: 'header_notification-bell_click',
          unreadNotifications: notificationsCount,
        })}
      >
        <IconButton
          data-test-id="notification-bell--badge"
          ref={buttonAnchorRef}
          onClick={onMenuToggle}
          className={classes.button}
        >
          <Badge
            badgeContent={notifications && notificationsCount}
            color="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{ badge: classes.badge }}
            data-test-id={
              notifications && notificationsCount && 'notification-bell--badge-has-notifications'
            }
          >
            <SvgLoader className={classes.bellIcon} {...BellIcon} />
          </Badge>
        </IconButton>
      </div>
      <NotificationList
        open={showNotifications}
        buttonAnchorEl={buttonAnchorRef.current}
        notifications={notifications}
        onMenuClose={onMenuClose}
      />
    </>
  )
}

NotificationBell.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  notificationsCount: PropTypes.number.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  showNotifications: PropTypes.bool.isRequired,
}

export default NotificationBell
