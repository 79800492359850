import { useReactiveVar } from '@apollo/client'
import React from 'react'

import { currentUser } from '@lib/apollo/apolloCache'
import { USER_ID_SESSION_STORAGE_KEY } from '@lib/constants'

import MasqueradeBar from '@components_pop/MasqueradeBar/view'

const MasqueradeBarContainer = () => {
  const currUser = useReactiveVar(currentUser)
  const userIdInSessionStorage = sessionStorage.getItem(USER_ID_SESSION_STORAGE_KEY)

  const endSession = () => {
    window.close()
  }

  if (!currUser?.isSwitched && !userIdInSessionStorage) {
    return null
  }

  return <MasqueradeBar user={currUser} clickHandler={endSession} />
}

export default MasqueradeBarContainer
