import { useApolloClient, useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { ThemeProvider } from '@material-ui/core/styles'

import { appErrors } from '@lib/apollo/apolloCache'
import popTheme from '@lib/theme'

import { TOAST_TYPES } from '@components_pop/Toast'

import useToast from '@hooks/useToast'

import PageLayout from './PageLayout'

const PageContainer = ({
  title,
  children,
  noMargin,
  pageTitle,
  subTitle,
  centered,
  hideFooter,
  fullHeight,
  noMarginDesktop,
  titleExtraContent,
  underTitleExtraContent,
  backUrl,
  hasSimpleHeader,
  forceHideNavbarSearch,
}) => {
  const client = useApolloClient()
  const appErrorsCache = useReactiveVar(appErrors)
  const { addToast } = useToast()

  useEffect(() => {
    if (appErrorsCache.length) {
      addToast({
        type: TOAST_TYPES.ERROR,
      })

      // This may be fined tuned later, but we need to clear this after each error
      // because we don't need it because it has been shown and we don't want to re show
      // the same error
      appErrors([])
    }
  }, [appErrorsCache])

  useEffect(() => {
    client.cache.gc()
  }, [])

  return (
    <ThemeProvider theme={popTheme}>
      <PageLayout
        title={title}
        noMargin={noMargin}
        backUrl={backUrl}
        pageTitle={pageTitle}
        subTitle={subTitle}
        centered={centered}
        hideFooter={hideFooter}
        noMarginDesktop={noMarginDesktop}
        titleExtraContent={titleExtraContent}
        underTitleExtraContent={underTitleExtraContent}
        fullHeight={fullHeight}
        hasSimpleHeader={hasSimpleHeader}
        forceHideNavbarSearch={forceHideNavbarSearch}
      >
        {children}
      </PageLayout>
    </ThemeProvider>
  )
}

PageContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  noMargin: PropTypes.bool,
  hasSimpleHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  forceHideNavbarSearch: PropTypes.bool,
  pageTitle: PropTypes.string,
  subTitle: PropTypes.string,
  backUrl: PropTypes.string,
  centered: PropTypes.bool,
  fullHeight: PropTypes.bool,
  noMarginDesktop: PropTypes.bool,
  titleExtraContent: PropTypes.object,
  underTitleExtraContent: PropTypes.object,
}

export default PageContainer
