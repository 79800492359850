/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import React from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import MessageIcon from '@public/svg/icons/message.svg'

import * as COLORS from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  notificationRow: {
    flexWrap: 'nowrap',
  },
  chatPreview: {
    '& p': {
      display: 'inline',
      margin: 0,
    },
    width: '60vw',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      width: 340,
    },
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  previewText: {
    display: 'inline',
    '& a': {
      color: COLORS.TEAL_ROBOTS,
    },
  },
  projectInfo: {
    fontSize: '0.75rem',
    color: COLORS.PURPLE_MEGATRON,
  },
  icon: {
    width: theme.spacing(5),
    height: 'auto',
    marginRight: theme.spacing(2),
  },
}))

const ChatMessageNotification = ({
  projectName,
  companyName,
  description,
  posterFirstName,
  posterLastName,
}) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.notificationRow}>
      <Grid item>
        <SvgLoader {...MessageIcon} className={classes.icon} />
      </Grid>
      <Grid item>
        <div className={classes.chatPreview}>
          <div>{`${posterFirstName} ${posterLastName} messaged:`}</div>
          <div
            className={classes.previewText}
            dangerouslySetInnerHTML={window.$mdSanitizedParse(description.substr(0, 50))}
          />
          {description.length > 50 && <span>...</span>}
        </div>
        <div className={classes.projectInfo}>
          {projectName}
          &nbsp;&nbsp;|&nbsp;&nbsp;
          {companyName}
        </div>
      </Grid>
    </Grid>
  )
}

ChatMessageNotification.propTypes = {
  projectName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  posterFirstName: PropTypes.string.isRequired,
  posterLastName: PropTypes.string.isRequired,
}

export default ChatMessageNotification
