import PropTypes from 'prop-types'
import React from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import CheckmarkIcon from '@public/svg/icons/checkmark-green.svg'
import OverdueIcon from '@public/svg/icons/due-overdue.svg'
import DueTodayIcon from '@public/svg/icons/due-today.svg'
import DueTomorrowIcon from '@public/svg/icons/due-tomorrow.svg'
import JourneyIcon from '@public/svg/icons/journey.svg'

import { PURPLE_MEGATRON } from '@lib/colors'
import { NOTIFICATION_TYPE } from '@lib/constants/notifications'
import { MOBILE_BREAKPOINT } from '@lib/theme'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  notificationRow: {
    flexWrap: 'nowrap',
  },
  todoPreview: {
    width: '60vw',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      width: 340,
    },
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  projectInfo: {
    fontSize: '0.75rem',
    color: PURPLE_MEGATRON,
  },
  icon: {
    width: theme.spacing(5),
    height: 'auto',
    marginRight: theme.spacing(2),
  },
}))

const NpiNotification = ({ notificationType, projectName, companyName, description }) => {
  const classes = useStyles()
  let icon = JourneyIcon
  let descriptionTitle = ''

  switch (notificationType) {
    case NOTIFICATION_TYPE.NPI_STEP_DUE_TODAY:
      icon = DueTodayIcon
      descriptionTitle = 'Step Due Today:'
      break

    case NOTIFICATION_TYPE.NPI_STEP_DUE_TOMORROW:
      icon = DueTomorrowIcon
      descriptionTitle = 'Step Due Tomorrow:'
      break

    case NOTIFICATION_TYPE.NPI_STEP_PAST_DUE:
      icon = OverdueIcon
      descriptionTitle = 'Step Past Due:'
      break

    case NOTIFICATION_TYPE.NPI_SUBMISSION_DELIVERED:
      icon = CheckmarkIcon
      descriptionTitle = 'Submission Delivered:'
      break

    case NOTIFICATION_TYPE.NPI_SUBMISSION_ACCEPTED:
      icon = CheckmarkIcon
      descriptionTitle = 'Submission Accepted:'
      break

    case NOTIFICATION_TYPE.NPI_SUBMISSION_REJECTED:
      descriptionTitle = 'Submission Rejected:'
      break

    case NOTIFICATION_TYPE.NPI_SUBMISSION_RECALLED:
      descriptionTitle = 'Submission Recalled By Owner:'
      break

    case NOTIFICATION_TYPE.NPI_GATE_REVIEW_STEP_COMPLETED:
      descriptionTitle = 'Gate Review Step Completed:'
      break

    default:
      break
  }

  return (
    <Grid container className={classes.notificationRow}>
      <Grid item>{icon && <SvgLoader {...icon} className={classes.icon} />}</Grid>
      <Grid item>
        <div>{descriptionTitle}</div>
        <div className={classes.todoPreview}>{description}</div>
        <div className={classes.projectInfo}>
          {projectName}
          &nbsp;&nbsp;|&nbsp;&nbsp;
          {companyName}
        </div>
      </Grid>
    </Grid>
  )
}

NpiNotification.propTypes = {
  notificationType: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default NpiNotification
