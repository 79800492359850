import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'

import { PURPLE_POLLYPOCKET } from '@lib/colors'

const useStyles = makeStyles(() => ({
  pulsin: {
    background: PURPLE_POLLYPOCKET,
    boxShadow: '0 0 0 0 rgba(188, 171, 209, 1)',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
    top: -4,
  },
  pulsinBig: {
    width: 16,
    height: 16,
    borderRadius: 10,
    top: -7,
    right: -5,
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(188, 171, 209, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(188, 171, 209, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(188, 171, 209, 0)',
    },
  },
}))

const PulsinBadge = ({ children, visible, isBig, additionalClass }) => {
  const classes = useStyles()
  if (!visible) return children

  return (
    <Badge
      color="primary"
      variant="dot"
      classes={{ dot: clsx(additionalClass ?? classes.pulsin, { [classes.pulsinBig]: isBig }) }}
    >
      {children}
    </Badge>
  )
}

PulsinBadge.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.object,
  isBig: PropTypes.bool,
  additionalClass: PropTypes.object,
}

export default PulsinBadge
