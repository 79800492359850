import React from 'react'

import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import * as COLORS from '@lib/colors'
import { FOOTER_HEIGHT, MOBILE_BREAKPOINT } from '@lib/theme'
import { serializeData } from '@lib/tracking'

const useStyles = makeStyles((theme) => ({
  footer: {
    height: FOOTER_HEIGHT,
    padding: '10px 15px',
    backgroundColor: COLORS.GRAY_WII,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      padding: '10px 75px',
    },
  },
  section: {
    fontSize: 14,
    color: COLORS.GRAY_NICKELARCADE,
    '& a': {
      color: 'inherit',
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <div className={classes.section}>
        © {new Date().getFullYear()} Gembah. All Rights Reserved.
      </div>
      <div className={classes.section}>
        <Link
          href="https://gembah.com/privacy-policy"
          target="_blank"
          data-tracking-info={serializeData({
            id: 'sidebar_nav-item-footer_click',
            route: 'https://gembah.com/privacy-policy',
          })}
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  )
}

export default Footer
