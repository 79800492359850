import PropTypes from 'prop-types'
import React from 'react'
import Confetti from 'react-dom-confetti'

import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'

import { MOBILE_BREAKPOINT } from '@lib/theme'

import useBreakpoint from '@hooks/useBreakpoint'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 9999,
    width: 0,
    height: 0,
    top: '50%',
    left: '50%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      whiteSpace: 'nowrap',
    },
  },
  title: {
    background: 'white',
    fontFamily: ['Wendy One', 'Roboto', 'Open Sans', 'Arial', 'sans-serif'],
    fontSize: 50,
  },
  text: {
    background: 'white',
    fontFamily: ['Wendy One', 'Roboto', 'Open Sans', 'Arial', 'sans-serif'],
    fontSize: 30,
  },
}))

const NotificationListEmptyState = ({ toss, title, text }) => {
  const classes = useStyles()
  const { isDesktop } = useBreakpoint()

  const config = {
    angle: 90,
    spread: isDesktop ? 130 : 25,
    startVelocity: 40,
    elementCount: 100,
    dragFriction: 0.13,
    duration: 1500,
    stagger: 0,
    width: '10px',
    height: '10px',
    perspective: '900px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  }

  return (
    <div id="confetti-root" className={classes.root}>
      <Confetti active={toss} config={config} />
      <Fade in={!title} timeout={2500}>
        <div className={classes.textContainer}>
          <div className={classes.title}>{title}</div>
          <div className={classes.text}>{text}</div>
        </div>
      </Fade>
    </div>
  )
}

NotificationListEmptyState.propTypes = {
  toss: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default NotificationListEmptyState
