import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'

import { confettiToss, confettiText } from '@lib/apollo/apolloCache'

import GembahConfetti from './view'

const GembahConfettiContainer = () => {
  const toss = useReactiveVar(confettiToss)
  const { title, text } = useReactiveVar(confettiText)

  useEffect(() => {
    if (toss) {
      confettiToss(false)

      // This is not the best way to handle the text fade out, but works for now.
      // E.g. What if user clicks on a different action that also sets the text?
      setTimeout(() => {
        confettiText({ title: '', text: '' })
      }, 2500)
    }
  }, [toss])

  return <GembahConfetti toss={toss} title={title} text={text} />
}

export default GembahConfettiContainer
