import gql from 'graphql-tag'

export const CLEAR_NOTIFICATION = gql`
  mutation ClearNotification($notificationId: ID!) {
    clearNotification(notificationId: $notificationId) {
      notification {
        id
        clearedAt
      }
    }
  }
`

export const CLEAR_ALL_NOTIFICATIONS = gql`
  mutation ClearAllNotifications {
    clearAllNotifications {
      user {
        id
      }
    }
  }
`
