import { useQuery } from '@apollo/client'
import React, { useState } from 'react'

import { confettiToss, confettiText } from '@lib/apollo/apolloCache'

import { GET_NOTIFICATIONS_LOCAL, GET_NOTIFICATION_COUNTS } from '@graphql/notifications/queries'

import NotificationBell from './view'

const NotificationBellContainer = () => {
  const [showNotifications, setShowNotifications] = useState(false)

  const {
    data: {
      notificationsWrapper: { notifications },
    },
  } = useQuery(GET_NOTIFICATIONS_LOCAL)

  const notificationsCounts = useQuery(GET_NOTIFICATION_COUNTS)
  const unreadNotificationsCount =
    notificationsCounts?.data?.unclearedNotificationsCount?.unreadNotificationsCount ?? 0
  const totalNotificationsCount =
    unreadNotificationsCount > 5000 ? '5000+' : unreadNotificationsCount

  // ===============================================================
  // App menu toggle handlers
  // ===============================================================

  const handleMenuToggle = () => {
    if (!notifications || !notifications.length) {
      confettiToss(true)
      confettiText({ title: 'Hooray!', text: 'You have no new notifications' })
    } else {
      setShowNotifications(!showNotifications)
    }
  }

  const handleMenuClose = () => {
    setShowNotifications(false)
  }

  // ===============================================================
  // Render
  // ===============================================================

  return (
    <NotificationBell
      notifications={notifications || []}
      notificationsCount={totalNotificationsCount}
      onMenuToggle={handleMenuToggle}
      onMenuClose={handleMenuClose}
      showNotifications={showNotifications}
    />
  )
}

export default NotificationBellContainer
