import MomentUtils from '@date-io/moment'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import PropTypes from 'prop-types'
import React from 'react'

import { IconButton } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import ArrowIcon from '@public/svg/icons/arrow-up.svg'

import { TEAL_DOLLHOUSE, GRAY_WII, TEAL_FURBY } from '@lib/colors'
import {
  DESKTOP_CENTER_SPACE,
  DESKTOP_DRAWER_WIDTH,
  DESKTOP_DRAWER_WIDTH_COLLAPSED,
  FONTS,
  MOBILE_APPBAR_HEIGHT,
  MOBILE_BREAKPOINT,
  MOBILE_CENTER_SPACE,
} from '@lib/theme'
import useRoute from '@lib/useRoute'

import FooterPop from '@components_pop/FooterPop'
import GembahConfetti from '@components_pop/GembahConfetti'
import HeaderPop from '@components_pop/HeaderPop'
import MasqueradeBar from '@components_pop/MasqueradeBar'
import SearchInput from '@components_pop/SearchInput'
import SvgLoader from '@components_pop/SvgLoader'
import Toast from '@components_pop/Toast'

import useBreakpoint from '@hooks/useBreakpoint'

import HeaderPopSimple from '../HeaderPopSimple'

// Dynamically import the RealTime component with no SSR to
// avoid instantiating an extraneous Pusher connection
// (which would persist for as long as the server is alive).
const RealTime = dynamic(() => import('@components_pop/Realtime'), { ssr: false })

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  pageTitle: {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column',
    '& > span': {
      fontSize: 10,
      color: TEAL_FURBY,
    },
    '& > h1': {
      fontSize: 15,
      fontWeight: 400,
      color: TEAL_DOLLHOUSE,
      lineHeight: '30px',
      margin: 0,
      fontFamily: FONTS.MERRI_WEATHER,
      padding: 0,
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      '& > h1': {
        fontSize: 24,
      },
    },
  },
  headContainer: {
    position: 'relative',
    display: 'flex',
    zIndex: 160,
    maxHeight: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `15px ${MOBILE_CENTER_SPACE}px`,
    background: GRAY_WII,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      padding: `15px ${DESKTOP_CENTER_SPACE}px`,
    },
  },
  backBtn: {
    position: 'absolute',
    left: 15,
    borderRadius: 5,
    padding: 5,
    background: 'rgba(222, 219, 217, 0.4)',
    '& > span > div': {
      width: 16,
      height: 16,
      transform: 'rotate(-90deg)',
      '& > div': {
        marginTop: -7,
      },
    },
  },
  outerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  innerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflowY: 'hidden',
  },
  drawerRootMobile: {
    marginTop: MOBILE_APPBAR_HEIGHT,
  },
  drawer: {
    whiteSpace: 'nowrap',
    top: MOBILE_APPBAR_HEIGHT,
    height: `calc(100% - ${MOBILE_APPBAR_HEIGHT}px)`, // hack - ensures drawer height doesn't grow past screen height
    width: theme.spacing(40), // mobile drawer width
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      overflow: 'visible',
      top: 0,
      height: '100%',
      width: DESKTOP_DRAWER_WIDTH,
      position: 'relative',
    },
  },
  userWorkStatusMobile: {
    marginTop: 20,
    '& > div': {
      width: '100%',
    },
  },
  collapsedDrawer: {
    width: DESKTOP_DRAWER_WIDTH_COLLAPSED,
  },
  pageLayoutWrapper: {
    overflowY: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  pageContentWrapper: ({ noMargin, fullHeight, centered, noMarginDesktop }) => ({
    padding: noMargin ? 0 : `0 ${MOBILE_CENTER_SPACE}px`,
    ...(fullHeight && {
      height: '100%',
    }),
    ...(centered && {
      display: 'flex',
      justifyContent: 'center',
      '& > div': {
        flex: 1,
      },
    }),
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      padding: noMargin || noMarginDesktop ? 0 : `20px ${DESKTOP_CENTER_SPACE}px`,
    },
  }),
  navBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  userMobileMenuWrapper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0`,
  },
  dividerSpacing: {
    margin: `${theme.spacing(2)}px 0`,
  },
}))

const PopPageLayout = ({
  title,
  children,
  noMargin,
  hasSimpleHeader,
  pageTitle,
  subTitle,
  backUrl,
  centered,
  hideFooter,
  fullHeight,
  noMarginDesktop,
  titleExtraContent,
  underTitleExtraContent,
  forceHideNavbarSearch,
}) => {
  const { isMobile, isDesktop } = useBreakpoint()
  const [goTo] = useRoute()

  const classes = useStyles({
    noMargin: noMargin && isMobile,
    fullHeight,
    centered,
    noMarginDesktop,
  })

  return (
    <div className={classes.root}>
      <Head>
        <title>{title}</title>
      </Head>
      <CssBaseline />
      <GembahConfetti />
      <RealTime />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.outerContainer}>
          <MasqueradeBar />
          {hasSimpleHeader ? (
            <HeaderPopSimple />
          ) : (
            <HeaderPop forceHideNavbarSearch={forceHideNavbarSearch} />
          )}
          {pageTitle && (
            <div className={classes.headContainer}>
              {backUrl && isDesktop && (
                <IconButton onClick={() => goTo(backUrl)} classes={{ root: classes.backBtn }}>
                  <SvgLoader {...ArrowIcon} />
                </IconButton>
              )}
              {pageTitle ? (
                <div data-test-id="page-title" className={classes.pageTitle}>
                  <h1>{pageTitle}</h1>
                  {subTitle && <span>{subTitle}</span>}
                </div>
              ) : (
                <span />
              )}
              {titleExtraContent}
            </div>
          )}
          {underTitleExtraContent}
          <Toast />
          <div className={classes.innerContainer}>
            <main className={classes.pageLayoutWrapper}>
              <div className={classes.pageContentWrapper}>
                {isMobile && !forceHideNavbarSearch && <SearchInput />}
                {children}
              </div>
              {!hideFooter && <FooterPop />}
            </main>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
}

PopPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  hideFooter: PropTypes.bool,
  pageTitle: PropTypes.string,
  subTitle: PropTypes.string,
  backUrl: PropTypes.string,
  centered: PropTypes.bool,
  hasSimpleHeader: PropTypes.bool,
  fullHeight: PropTypes.bool,
  forceHideNavbarSearch: PropTypes.bool,
  noMarginDesktop: PropTypes.bool,
  titleExtraContent: PropTypes.object,
  underTitleExtraContent: PropTypes.object,
}

export default PopPageLayout
