import { useReactiveVar } from '@apollo/client'
import { faSignOut } from '@fortawesome/pro-light-svg-icons'
import { faMask } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { currentAccountUser } from '@lib/apollo/apolloCache'
import { MOBILE_BREAKPOINT } from '@lib/theme'
import { ACCOUNT_TYPES } from '@lib/userAuth'

import useBreakpoint from '@hooks/useBreakpoint'

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    background: '#EA355D',
    color: 'white',
    fontSize: 13,
    height: 40,
    position: 'relative',
    top: 72,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    width: '55%',
  },
  name: {
    width: '100%',
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btn: {
    color: 'white',
    borderColor: 'white',
    margin: 0,
  },
  btnText: {
    marginLeft: theme.spacing(0.5),
  },
  userType: {
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    mainWrapper: {
      fontSize: theme.fontSize,
      height: 50,
      padding: '0 16px',
      top: 0,
    },
    btn: {
      margin: 0,
      padding: '2px 6px',
    },
  },
}))

const MasqueradeBar = ({ user, clickHandler }) => {
  const classes = useStyles()
  const { isDesktop } = useBreakpoint()
  const accountUser = useReactiveVar(currentAccountUser)

  const getUserName = () => user?.userAccounts?.[0]?.fullName

  const getUserType = () => {
    switch (accountUser?.account?.type) {
      case ACCOUNT_TYPES.GEMBAH:
        return 'Gembah'
      case ACCOUNT_TYPES.EXPERT:
        return 'Contractor'
      case ACCOUNT_TYPES.CREATOR:
        return 'Client'
      default:
        return 'Unknown User Type'
    }
  }

  return (
    <div data-test-id="masquerade-menu" className={classes.mainWrapper}>
      <div className={classes.info}>
        <FontAwesomeIcon icon={faMask} />
        <div className={classes.name}>
          {isDesktop && <span>Masquerading as </span>}
          <b>{getUserName()}</b>
          <span className={classes.userType}>( {getUserType()} )</span>
        </div>
      </div>

      <div>
        <Button variant="outlined" color="primary" className={classes.btn} onClick={clickHandler}>
          <FontAwesomeIcon icon={faSignOut} />
          <span className={classes.btnText}>End Session</span>
        </Button>
      </div>
    </div>
  )
}

MasqueradeBar.propTypes = {
  user: PropTypes.object,
  clickHandler: PropTypes.func,
}

export default MasqueradeBar
