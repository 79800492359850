import { useQuery, useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  companiesSearch,
  usersSearch,
  projectsSearch,
  projectsOrderBy,
  companiesOrderBy,
  usersOrderBy,
  paginationOffset,
} from '@lib/apollo/apolloCache'

import { GET_SEARCH_TERMS } from '@graphql/searchInput/queries'

import {
  DEFAULT_TABLES_SORT_COMPANY_FIELD,
  DEFAULT_TABLES_SORT_PROJECT_FIELD,
  DEFAULT_TABLES_SORT_USER_FIELD,
} from '@components_pop/Table/constants'

import SearchInput from './view'

const PATHS_TO_MODULES = {
  '/': {
    text: 'Projects',
    name: 'projectsSearch',
    searchVar: projectsSearch,
    orderByVar: projectsOrderBy,
    defaultSort: DEFAULT_TABLES_SORT_PROJECT_FIELD,
  },
  '/project/[projectSlug]/[...subRoute]': {
    text: 'Projects',
    name: 'projectsSearch',
    searchVar: projectsSearch,
    orderByVar: projectsOrderBy,
    shouldReset: true,
    callback: async ({ router, setLoading }) => {
      setLoading(true)

      await router.push('/')
      setLoading(false)
    },
  },
  '/companies': {
    text: 'Companies',
    name: 'companiesSearch',
    searchVar: companiesSearch,
    orderByVar: companiesOrderBy,
    defaultSort: DEFAULT_TABLES_SORT_COMPANY_FIELD,
  },
  '/users': {
    text: 'Users',
    name: 'usersSearch',
    searchVar: usersSearch,
    orderByVar: usersOrderBy,
    defaultSort: DEFAULT_TABLES_SORT_USER_FIELD,
  },
  '/admin-console/companies': {
    text: 'companies',
    name: 'companiesSearch',
    searchVar: companiesSearch,
    orderByVar: companiesOrderBy,
    defaultSort: DEFAULT_TABLES_SORT_COMPANY_FIELD,
  },
  '/admin-console/creator': {
    text: 'creators',
    name: 'usersSearch',
    searchVar: usersSearch,
  },
  '/admin-console/expert': {
    text: 'experts',
    name: 'usersSearch',
    searchVar: usersSearch,
  },
  '/admin-console/factory': {
    text: 'factory users',
    name: 'usersSearch',
    searchVar: usersSearch,
  },
  '/admin-console/gembah': {
    text: 'gembah',
    name: 'usersSearch',
    searchVar: usersSearch,
  },
}

const SearchInputContainer = ({ hasAlternateDesign }) => {
  const { data } = useQuery(GET_SEARCH_TERMS)
  const router = useRouter()
  const currentModule = PATHS_TO_MODULES[router.pathname] ?? PATHS_TO_MODULES[router.asPath]

  const inputText = data[currentModule?.name] || ''
  const [isLoading, setIsLoading] = useState(false)
  const searchVariable = currentModule ? useReactiveVar(currentModule.searchVar) : null
  const orderByVariable = currentModule?.orderByVar ? currentModule.orderByVar : null

  const { handleSubmit, register, setValue } = useForm({
    defaultValues: {
      globalSearch: inputText,
    },
  })

  useEffect(() => {
    if (currentModule?.shouldReset) {
      currentModule.searchVar('')
      setValue('globalSearch', '')
    }
  }, [currentModule])

  useEffect(() => {
    if (orderByVariable) {
      // In order for the initial trigram similarity results to appear as expected,
      // we MUST explicitly send NULL as the initial order by on each search query.
      // This way, the user is returned the most relevant search results at towards
      // the top of the list.
      // After this initial resultset, they can sort by column as usual.
      if (searchVariable) {
        orderByVariable(null)
      } else {
        // we reset the default sort for that spcific table
        orderByVariable(currentModule?.defaultSort || null)
      }
    }
    // Pagination offset must be reset to zero!
    paginationOffset(0)
    setValue('globalSearch', searchVariable)
  }, [searchVariable])

  const setLoading = (loading) => setIsLoading(loading)

  const searchRecords = ({ globalSearch }) => {
    currentModule.searchVar(globalSearch)
    // the linter does not understand this yet
    /* eslint-disable no-unused-expressions */
    currentModule.callback?.({
      router,
      globalSearch,
      setLoading,
    })
  }

  if (!currentModule) {
    // search not supported for this route
    return null
  }

  return (
    <SearchInput
      ref={register}
      hasAlternateDesign={hasAlternateDesign}
      onSubmit={handleSubmit(searchRecords)}
      inputText={inputText}
      currentModule={currentModule}
      isLoading={isLoading}
      setValue={setValue}
    />
  )
}

SearchInputContainer.propTypes = {
  hasAlternateDesign: PropTypes.bool,
}

export default SearchInputContainer
