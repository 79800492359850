import { useReactiveVar } from '@apollo/client'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { makeStyles } from '@material-ui/core/styles'

import HamburgerIcon from '@public/svg/icons/hamburger.svg'

import { currentUser } from '@lib/apollo/apolloCache'
import { COMMON_BLACK, COMMON_WHITE } from '@lib/colors'
import { serializeData } from '@lib/tracking'
import { ACCOUNT_USER_STATUS } from '@lib/userAuth'

import OutOfOfficeTooltip from '@components_pop/OutOfOfficeTooltip'
import PulsinBadge from '@components_pop/PulsinBadge'
import SvgLoader from '@components_pop/SvgLoader'
import UserLetterAvatar from '@components_pop/users/UserLetterAvatar'

import UserProfileMenu from '../UserProfileMenu'

const useStyles = makeStyles(() => ({
  profileButton: {
    textTransform: 'none',
    color: COMMON_BLACK,
    fontSize: '1rem',
    '& [class*="MuiAvatar-circle"]': {
      fontSize: '1rem',
    },
    background: COMMON_WHITE,
    borderRadius: 25,
    '&:hover': {
      background: COMMON_WHITE,
    },
  },
  hamburgerIcon: {
    width: 25,
    height: 30,
  },
}))

const UserProfilePillView = ({ onMenuClose, onMenuToggle, anchorEl }) => {
  const user = useReactiveVar(currentUser)
  const profile = user.profile ?? user
  const classes = useStyles()
  return (
    <>
      <ClickAwayListener onClickAway={onMenuClose}>
        <Button
          size="medium"
          onClick={onMenuToggle}
          className={classes.profileButton}
          startIcon={<SvgLoader {...HamburgerIcon} className={classes.hamburgerIcon} />}
          endIcon={
            <PulsinBadge
              visible={!anchorEl && user.status === ACCOUNT_USER_STATUS.INVITE_ACCEPTED}
              isBig
            >
              <OutOfOfficeTooltip user={user} isForAvatar>
                <UserLetterAvatar
                  alt={profile.name ?? profile.fullName}
                  src={profile.avatar?.url}
                  firstName={profile.firstName}
                  lastName={profile.lastName}
                />
              </OutOfOfficeTooltip>
            </PulsinBadge>
          }
          data-test-id="page-header--user-profile-button"
          data-tracking-info={serializeData({ id: 'header_new-project_click' })}
        />
      </ClickAwayListener>
      <UserProfileMenu anchorEl={anchorEl} />
    </>
  )
}

UserProfilePillView.propTypes = {
  onMenuClose: PropTypes.func,
  onMenuToggle: PropTypes.func,
  anchorEl: PropTypes.shape({}),
}

UserProfilePillView.defaultProps = {
  onMenuClose: () => {},
  onMenuToggle: () => {},
  anchorEl: null,
}

export default UserProfilePillView
