import PropTypes from 'prop-types'
import React from 'react'

import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'

import UmbrellaIcon from '@public/svg/icons/ooo.svg'

import SvgLoader from '@components_pop/SvgLoader'
import { AVAILABILITY_CHOICES } from '@components_pop/profile/UserProfileMenu/container'

const OutOfOfficeTooltip = ({ children, user, isForAvatar, avatarProps = {} }) => {
  if (!user) return children

  const { availability } = user ?? {}
  const isOOO = AVAILABILITY_CHOICES.OOO === availability

  const toApplyAvatarProps = {
    ...avatarProps,
    color: 'secondary',
    overlap: 'circle',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    variant: 'dot',
  }

  const renderContentForTooltip = () =>
    isForAvatar ? (
      <Badge data-test-id={`out-of-office-tooltip-${user?.email}`} {...toApplyAvatarProps}>
        {children}
      </Badge>
    ) : (
      <span data-test-id={`out-of-office-tooltip-${user?.email}`}>
        {children}
        <SvgLoader {...UmbrellaIcon} style={{ marginLeft: 10, width: 20, height: 20 }} />
      </span>
    )

  return isOOO ? (
    <Tooltip placement="right-end" arrow title="Out Of the Office">
      {renderContentForTooltip()}
    </Tooltip>
  ) : (
    children
  )
}

OutOfOfficeTooltip.propTypes = {
  user: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isForAvatar: PropTypes.bool,
  avatarProps: PropTypes.object,
}

export default OutOfOfficeTooltip
