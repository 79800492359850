import PropTypes from 'prop-types'
import React from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import * as COLORS from '@lib/colors'
import { MOBILE_BREAKPOINT } from '@lib/theme'

import MediaIcon from '@components_pop/attachments/MediaIcon'

const useStyles = makeStyles((theme) => ({
  notificationRow: {
    flexWrap: 'nowrap',
  },
  mediaDescriptionPreview: {
    whiteSpace: 'nowrap',
    width: '60vw',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      width: 340,
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  projectInfo: {
    fontSize: '0.75rem',
    color: COLORS.PURPLE_MEGATRON,
  },
  icon: {
    width: theme.spacing(5),
    height: 'auto',
    marginRight: theme.spacing(2),
  },
}))

const AttachmentNotification = ({
  projectName,
  companyName,
  description,
  posterFirstName,
  posterLastName,
  mediaMimetype,
}) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.notificationRow}>
      <Grid item>
        <MediaIcon className={classes.icon} mimetype={mediaMimetype} />
      </Grid>
      <Grid item>
        <div>{`${posterFirstName} ${posterLastName} uploaded a new document:`}</div>
        <div className={classes.mediaDescriptionPreview}>{description}</div>
        <div>({mediaMimetype})</div>
        <div className={classes.projectInfo}>
          {projectName}
          &nbsp;&nbsp;|&nbsp;&nbsp;
          {companyName}
        </div>
      </Grid>
    </Grid>
  )
}

AttachmentNotification.propTypes = {
  projectName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  posterFirstName: PropTypes.string.isRequired,
  posterLastName: PropTypes.string.isRequired,
  mediaMimetype: PropTypes.string.isRequired,
}

export default AttachmentNotification
