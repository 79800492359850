import { useReactiveVar } from '@apollo/client'

import { featureFlags } from '@lib/apollo/apolloCache'

const TO_REMOVE_QUERIES = ['GetChatChannelMessages', 'GetUnclearedNotifications']

const usePusherHelpers = () => {
  const { isRealtimeActive } = useReactiveVar(featureFlags)

  const removePollingGraph = (queryCollection = []) => {
    return isRealtimeActive
      ? queryCollection.filter((qc) => !TO_REMOVE_QUERIES.includes(qc))
      : queryCollection
  }

  const removePollingGraphVar = (queryCollection = []) => {
    return isRealtimeActive
      ? queryCollection.filter((qc) => {
          const queryName = qc?.query?.definitions[0]?.name?.value
          return !TO_REMOVE_QUERIES.includes(queryName)
        })
      : queryCollection
  }

  return {
    removePollingGraph,
    removePollingGraphVar,
  }
}

export default usePusherHelpers
