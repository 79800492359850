import Link from 'next/link'
import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'

import Logo from '@public/svg/logo-color.svg'

import * as COLORS from '@lib/colors'
import { DESKTOP_CENTER_SPACE, MOBILE_BREAKPOINT, MOBILE_CENTER_SPACE } from '@lib/theme'

import SvgLoader from '@components_pop/SvgLoader'

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    backgroundColor: COLORS.GRAY_WII,
    padding: `0 ${MOBILE_CENTER_SPACE}px`,
    borderBottom: `1px solid ${COLORS.TEAL_DOLLHOUSE}`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 80,
    padding: 0,
  },
  logoAndSearchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    appBarRoot: {
      padding: `0 ${DESKTOP_CENTER_SPACE}px`,
    },
  },
}))

const HeaderPopSimple = () => {
  const classes = useStyles()

  return (
    <>
      <AppBar position="fixed" classes={{ root: classes.appBarRoot }} elevation={0}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <div className={classes.logoAndSearchWrapper}>
            <Link href="/">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <SvgLoader {...Logo} />
              </a>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} /> {/* AppBar spacer */}
    </>
  )
}

export default HeaderPopSimple
