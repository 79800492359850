import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

import { Divider } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import FeedbackIcon from '@public/svg/icons/feedback.svg'
import JourneyIcon from '@public/svg/icons/journey.svg'
import LinkIcon from '@public/svg/icons/link.svg'
import LogoutIcon from '@public/svg/icons/logout.svg'
import OOOEmptyIcon from '@public/svg/icons/ooo-empty.svg'
import OOOIcon from '@public/svg/icons/ooo.svg'
import PasswordIcon from '@public/svg/icons/password.svg'
import ProfileIcon from '@public/svg/icons/profile-card.svg'
import CogIcon from '@public/svg/icons/settings-icon.svg'
import WorkingEmptyIcon from '@public/svg/icons/working-empty.svg'
import WorkingIcon from '@public/svg/icons/working.svg'

import { currentAccountUser, currentUser } from '@lib/apollo/apolloCache'
import {
  COMMON_WHITE,
  GREEN_GLO_WORM,
  PURPLE_KOOSH,
  PURPLE_POLLYPOCKET,
  TEAL_DOLLHOUSE,
} from '@lib/colors'
import { MOBILE_BREAKPOINT, MOBILE_MAX } from '@lib/theme'
import { serializeData } from '@lib/tracking'
import { ACCOUNT_USER_STATUS, hasPermission, hasUserGroup, LEGACY_USER_GROUPS } from '@lib/userAuth'

import { availableAdminRoutes } from '@components_pop/AdminConsole/utils'
import PulsinBadge from '@components_pop/PulsinBadge'
import SvgLoader from '@components_pop/SvgLoader'

import { AVAILABILITY_CHOICES } from './container'

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    minWidth: 'auto',
    '& > div': {
      width: 25,
      height: 25,
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: 'rgba(240, 255, 253, 1)',
    },
  },
  activeListItem: {
    backgroundColor: 'rgba(240, 255, 253, 1)',
  },
  listItemText: {
    fontSize: 18,
    padding: '5px 15px',
    textAlign: 'left',
    color: TEAL_DOLLHOUSE,
    width: '100%',
  },
  userMenuWrapper: {
    top: '84px !important',
    background: COMMON_WHITE,
    '& > ul': {
      padding: 0,
    },
  },
  workingOptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    background: GREEN_GLO_WORM,
    fontWeight: 'bold',
    '& > span': {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: 'rgba(240, 255, 253, 1)',
    },
  },
  workingOptionWrapperOffline: {
    background: PURPLE_KOOSH,
    '& > span': {
      textAlign: 'right',
    },
  },
  [theme.breakpoints.down(MOBILE_MAX)]: {
    userMenuWrapper: {
      maxHeight: '100%',
      maxWidth: '100%',
      left: '0 !important',
      right: '0 !important',
      bottom: 0,
    },
  },
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    userMenuWrapper: {
      top: '70px !important',
      minWidth: 350,
      boxShadow: '0 0 15px rgba(149, 145, 142, 0.5)',
      borderRadius: 20,
    },
  },
  pulsin: {
    background: PURPLE_POLLYPOCKET,
    boxShadow: '0 0 0 0 rgba(188, 171, 209, 1)',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
    top: -4,
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(188, 171, 209, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(188, 171, 209, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(188, 171, 209, 0)',
    },
  },
}))

const UserProfileMenuView = ({ anchorEl, onStatusChange }) => {
  const classes = useStyles()
  const { pathname } = useRouter()
  const user = useReactiveVar(currentUser)
  const accountUser = useReactiveVar(currentAccountUser)
  const isUserOOO = user.availability === AVAILABILITY_CHOICES.OOO

  const workAvailabilityClasses = clsx({
    [classes.workingOptionWrapper]: true,
    [classes.workingOptionWrapperOffline]: isUserOOO,
  })

  const navItems = [
    {
      text: 'My Profile',
      url: '/profile',
      icon: ProfileIcon,
      isUserAllowed: true,
      hasBadge: true,
      testId: 'nav--profile',
    },
    {
      text: 'Reset Password',
      url: '/password-reset',
      icon: PasswordIcon,
      isUserAllowed: true,
      testId: 'nav--password-reset',
      hasDivider: true,
    },
    {
      text: 'Admin Console',
      url: `/admin-console/${availableAdminRoutes(accountUser).firstRoute}`,
      icon: CogIcon,
      isUserAllowed: hasPermission.ADMIN(accountUser),
      testId: 'nav--admin-console',
    },
    {
      target: '_blank',
      text: 'Journey Templates',
      url: '',
      externalUrl: `https://${process.env.API_HOST}/admin/npi`,
      icon: JourneyIcon,
      isUserAllowed: hasUserGroup(user, [LEGACY_USER_GROUPS.NPI_SUPER]),
      testId: 'nav--admin',
    },
    {
      text: 'Feedback',
      url: '/feedback',
      icon: FeedbackIcon,
      isUserAllowed: true,
      testId: 'nav--feedback',
    },
  ]

  return (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={!!anchorEl}
      classes={{
        paper: classes.userMenuWrapper,
      }}
    >
      {hasPermission.GEMBAH_OR_EXPERT(accountUser) && (
        <div key="toggle-work">
          <MenuItem
            data-test-id="user-work-status-toggle"
            classes={{ root: workAvailabilityClasses }}
            onClick={onStatusChange}
          >
            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <SvgLoader {...(isUserOOO ? WorkingEmptyIcon : WorkingIcon)} />
            </ListItemIcon>

            <Typography classes={{ root: classes.listItemText }} variant="inherit" noWrap>
              {isUserOOO ? 'Out of the Office' : 'Working'}
            </Typography>

            <ListItemIcon classes={{ root: classes.iconRoot }}>
              <SvgLoader {...(isUserOOO ? OOOIcon : OOOEmptyIcon)} />
            </ListItemIcon>
          </MenuItem>
          <Divider />
        </div>
      )}
      {navItems.map(
        (navItem) =>
          navItem.isUserAllowed && (
            <div key={navItem.testId}>
              <Link href={navItem.url}>
                <MenuItem
                  component="a"
                  href={navItem.externalUrl}
                  target={navItem.target}
                  classes={{
                    root: clsx(classes.listItem, {
                      [classes.activeListItem]: pathname === navItem.url,
                    }),
                  }}
                >
                  <PulsinBadge
                    visible={
                      navItem.hasBadge && user.status === ACCOUNT_USER_STATUS.INVITE_ACCEPTED
                    }
                  >
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <SvgLoader {...navItem.icon} />
                    </ListItemIcon>
                  </PulsinBadge>

                  <Typography
                    classes={{ root: classes.listItemText }}
                    variant="inherit"
                    noWrap
                    data-test-id={navItem.testId}
                  >
                    {navItem.text}
                  </Typography>

                  {navItem.externalUrl && (
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <SvgLoader {...LinkIcon} />
                    </ListItemIcon>
                  )}
                </MenuItem>
              </Link>
              {navItem.hasDivider && <Divider />}
            </div>
          )
      )}
      <Divider />
      <MenuItem
        key="logout-btn"
        component="a"
        href="/logout"
        classes={{ root: classes.listItem }}
        data-tracking-info={serializeData({
          id: 'header_user-menu-logout_click',
        })}
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <SvgLoader {...LogoutIcon} />
        </ListItemIcon>

        <Typography
          classes={{ root: classes.listItemText }}
          variant="inherit"
          noWrap
          data-test-id="logout-btn"
        >
          Log Out
        </Typography>
      </MenuItem>
    </Menu>
  )
}

UserProfileMenuView.propTypes = {
  anchorEl: PropTypes.shape({}),
  onStatusChange: PropTypes.func,
}

UserProfileMenuView.defaultProps = {
  anchorEl: null,
}

export default UserProfileMenuView
