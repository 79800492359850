import React, { useState } from 'react'

import UserProfilePill from './view'

const UserProfilePillContainer = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <UserProfilePill
      anchorEl={anchorEl}
      onMenuToggle={handleMenuToggle}
      onMenuClose={handleMenuClose}
    />
  )
}

export default UserProfilePillContainer
