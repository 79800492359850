export const NOTIFICATION_TYPE = {
  CHAT_MESSAGE: 'chat-message',
  AT_MENTION: 'at-mention',
  CHECKLIST_ITEM_DUE_TODAY: 'checklist-item-due-today',
  CHECKLIST_ITEM_DUE_TOMORROW: 'checklist-item-due-tomorrow',
  CHECKLIST_ITEM_PAST_DUE: 'checklist-item-past-due',
  NPI_STEP_DUE_TODAY: 'npi-step-due-today',
  NPI_STEP_DUE_TOMORROW: 'npi-step-due-tomorrow',
  NPI_STEP_PAST_DUE: 'npi-step-past-due',
  NPI_SUBMISSION_DELIVERED: 'npi-submission-delivered',
  NPI_SUBMISSION_ACCEPTED: 'npi-submission-accepted',
  NPI_SUBMISSION_REJECTED: 'npi-submission-rejected',
  NPI_GATE_REVIEW_STEP_COMPLETED: 'npi-gate-review-step-completed',
  NPI_SUBMISSION_RECALLED: 'npi-submission-recalled',
}
export const NPI_NOTIFICATION_TYPES = [
  NOTIFICATION_TYPE.NPI_STEP_DUE_TODAY,
  NOTIFICATION_TYPE.NPI_STEP_DUE_TOMORROW,
  NOTIFICATION_TYPE.NPI_STEP_PAST_DUE,
  NOTIFICATION_TYPE.NPI_SUBMISSION_DELIVERED,
  NOTIFICATION_TYPE.NPI_SUBMISSION_ACCEPTED,
  NOTIFICATION_TYPE.NPI_SUBMISSION_REJECTED,
  NOTIFICATION_TYPE.NPI_GATE_REVIEW_STEP_COMPLETED,
  NOTIFICATION_TYPE.NPI_SUBMISSION_RECALLED,
]
