import { gql } from '@apollo/client'

// eslint-disable-next-line import/prefer-default-export
export const GET_SEARCH_TERMS = gql`
  query GetSearchTerms {
    projectsSearch @client
    companiesSearch @client
    usersSearch @client
    projectsProductionDashboardSearch @client
    projectsAmDashboardSearch @client
  }
`
